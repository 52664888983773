<template>
    <div class="right-menu" v-loading.fullscreen.lock="settingsLoader">
        
        <subHeader pageName="PRIVACY POLICY"/>

        <tutorialsAction />

        <ValidationObserver ref="notificationObserver" v-slot="{ handleSubmit }">
            <div class="db-container">
                <ValidationProvider name="privacy policy" rules="required" v-slot="{ errors }" >
                    <div class="allCustomersField" id="privacy-policy-field">
                        <quill-editor  
                            name="privacy-policy"
                            form="usrform"
                            :options="editorOptions"
                            v-model="privacyPolicy"
                            :disabled="tourIsActive"
                        >
                        </quill-editor>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                <div class="sendMessageBtn">
                    <loaderBtn v-if="submitLoader"/>
                    <button @click.prevent="handleSubmit(savePolicy)" :disabled="tourIsActive" id="save-btn" v-else>SAVE</button>
                </div>
            </div>
        </ValidationObserver>

        <!-- vue tour -->
        <v-tour name="privacyPolicyTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>
<script>
import subHeader from "@/components/subHeader.vue";
import {MessageBox, Message} from 'element-ui';
import Multiselect from 'vue-multiselect'
import {mapGetters} from 'vuex';
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    metaInfo() {
        return {
            title: 'Settings | Privacy Policy | YeetCommerce',
        };
    },
    data() {
        return {
            submitLoader: false,
            privacyPolicy:'',
            editorOptions:{
                placeholder: 'Write a privacy policy...',
                theme: 'snow',
                modules:{
                    toolbar:{
                        container:[ 
                            [{ "font": [] },],
                            [{'header': [1, 2, 3, 4, 5, 6, false]}],
                            ["blockquote", "code-block"],
                            ['bold', 'italic', 'underline', 'strike'],
                            ["clean"],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                            [{ 'script': 'sub'}, { 'script': 'super' }],
                            [{'color': []}, {'background': []}],
                            [{ 'direction': 'rtl' }, { 'align': [] }],
                        ],
                    }
                }
            },
            //vue-tour
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //privacy policy steps
                {
                    target: '#privacy-policy-field',
                    content: `<strong>Privacy Policy</strong><br>You can add your privacy policy here.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'bottom'
                    }
                },
                {
                    target: '#save-btn',
                    content: `<strong>Save Privacy Policy</strong><br>Added privacy policy can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'right'
                    }
                },
            ],
            tourIsActive:false
        }
    },
    components: {
        subHeader,
        Multiselect,
        tutorialsAction,
        loaderBtn
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            settingsLoader:'settings_module/isPending'
        }),
    },
    watch:{
        'settings':{
            handler:function(val){

                this.privacyPolicy = val.privacy_policy;

            },deep:true
        }
    },
    methods:{

        async savePolicy(){

            let formData = new FormData();

            formData.append('policy',this.privacyPolicy);

            this.submitLoader = true;
            try{
                let res = await this.$axios.post('/store-settings/update/privacy-policy',formData);
                if(res.data.status_code == "1282"){
                    
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.$store.dispatch('settings_module/fetchStoreSetting');

                }
            }catch(error){
                
                if(error.response){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response.data.message,
                    });

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }
                
            }finally{

                this.submitLoader = false;

            }
        },
        //tour methods
        startPrivacyPolicyTour(){

            this.$tours.privacyPolicyTour.start();

            this.tourIsActive = true;

        },
        handleNextStep(currentStep){
        },
        handlePreviousStep(currentStep){
        },
        handleTourStop(){

            this.tourIsActive = false;

        },
        handleTourFinish(){

            this.tourIsActive = false;

        },
        handleTourSkip(){

            this.tourIsActive = false;

        },
    },
    async beforeMount(){
        
        if(!this.settingsLoader){

            this.privacyPolicy = this.settings.privacy_policy;

        }
        
    },
    beforeRouteLeave(to, from, next){

        if((this.privacyPolicy != this.settings.privacy_policy) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                next();

            }).catch(()=>{})

        }else{

            next();

        }
        
    },
}
</script>